





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TranslationForm from '@/components/TranslationForm.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { TranslationModel } from '@/common/models';
import { Button } from 'element-ui';
import _ from 'lodash';

@Component({
  components: {
    TranslationForm,
    ConfirmationDialog,
    'el-button': Button,
  },
})
export default class Edit extends Vue {
  @Prop() id: string;

  translation: TranslationModel = null;
  editDialog = true;
  resetDialog = false;
  discardDialog = false;
  saveDialog = false;
  removeDialog = false;
  errorMessage = false;
  saveLoading = false;

  savedScroll = 0;

  get editTranslation(): TranslationModel {
    return this.$store.state.edit.translation;
  }

  get editResult() {
    return this.$store.state.edit.result;
  }

  get isChanged(): boolean {
    return !_.isEqual(this.translation, this.editTranslation);
  }

  @Watch('editTranslation')
  editTranslationWatch() {
    this.translation = this.editTranslation ? _.cloneDeep(this.editTranslation) : null;
  }

  @Watch('editResult')
  editResultWatch() {
    if (this.editResult) {
      this.saveLoading = false;
      if (this.editResult.success) {
        this.editDialog = false;
        this.$store.commit('updateEditableTranslation', null);
        this.$store.commit('updateEditResult', null);
        this.openTranslationsPage();
      } else {
        this.errorMessage = true;
      }
    }
  }

  mounted() {
    this.savedScroll = document.documentElement.scrollTop;
    (document.querySelector('.v-application') as any).style.marginTop = `-${this.savedScroll}px`;
    document.body.style.overflow = 'hidden';

    this.$store.dispatch('loadTranslationToEdit', this.id);
  }

  destroyed() {
    (document.querySelector('.v-application') as any).style.marginTop = '';
    document.body.style.overflow = '';
    document.documentElement.scrollTop = this.savedScroll;
  }

  onResetClick() {
    this.resetDialog = true;
    this.closeKeyboard();
  }

  onDiscardClick() {
    if (this.isChanged) {
      this.discardDialog = true;
    } else {
      this.onDiscard(true);
    }
    this.closeKeyboard();
  }

  onSaveClick() {
    if (this.isChanged) {
      this.saveDialog = true;
    } else {
      this.onDiscard(true);
    }
    this.closeKeyboard();
  }

  onRemoveClick() {
    this.removeDialog = true;
    this.closeKeyboard();
  }

  onReset(reset: boolean) {
    this.resetDialog = false;

    if (reset) {
      this.translation = this.editTranslation ? _.cloneDeep(this.editTranslation) : null;
    }
  }

  onDiscard(discard: boolean) {
    this.discardDialog = false;

    if (discard) {
      this.editDialog = false;
      this.openTranslationsPage();
    }
  }

  onSave(save: boolean) {
    this.saveDialog = false;

    if (save) {
      this.saveLoading = true;
      this.$store.dispatch('editTranslation', this.translation);
    }
  }

  onRemove(remove: boolean) {
    this.removeDialog = false;

    if (remove) {
      this.$store.dispatch('removeTranslation', this.id);
    }
  }

  closeErrorMessage() {
    this.errorMessage = false;
  }

  closeKeyboard() {
    this.$store.commit('closeKeyboard');
  }

  private openTranslationsPage() {
    const query: any = {
      page: this.$store.state.translations.currentPage.toString(),
      force: 'true',
    };

    if (this.$store.state.translations.wordList) {
      query.wordlist = 'true';
    }

    this.$router.push({ name: 'home', query });
  }
}
